@value modalZIndex, max-small, max-medium, max-large, max-xlarge, min-small, min-medium, min-large, min-xlarge from "@/styles/variables.module.css";

.container {
  display: flex;
  position: fixed;
  transition: transform 0.3s ease-in-out;
  width: 100dvw;
  height: 100dvh;
}

.container.open {
  z-index: modalZIndex;
}

.top {
  bottom: 100%;
  left: 0;
}

.container.top {
  flex-direction: column;
  justify-content: center;
  align-items: flex;
}

@media max-large {
  .container.top {
    justify-content: flex-start;
    align-items: center;
  }
}

.top.open {
  transform: translateY(100%);
}

.left {
  top: 0;
  right: 100%;
}

.container.left {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.left.open {
  transform: translateX(100%);
}

.right {
  top: 0;
  left: 100%;
}

.container.right {
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: flex-start;
}

.right.open {
  transform: translateX(-100%);
}

.bottom {
  top: 100%;
  left: 0;
}

.container.bottom {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media max-large {
  .container.bottom {
    justify-content: flex-end;
  }
}

.bottom.open {
  transform: translateY(-100%);
}
