@value max-small, max-medium, min-large from "@/styles/variables.module.css";

.filtersContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 16px 0 32px 0;
  width: 100%;

  @media max-medium {
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
  }
}

.filterSelect {
  max-width: 300px;

  @media max-medium {
    width: 100%;
    max-width: none;
  }
}

.selectLabel {
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 700;
  white-space: nowrap;
}

.button {
  width: 100%;
}

.mobileContainer {
  display: none;

  @media max-medium {
    display: block;
  }
}

.desktopContainer {
  display: block;

  @media max-medium {
    display: none;
  }
}

.filtersCounter {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #000;
  width: 24px;
  height: 24px;
  color: #fff;
  font-size: 12px;
  font-family: Oxygen;
}

.selectedFiltersWrapper {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  padding: 16px 0;
  width: 100%;
  justify-content: center;
}
