.container {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  background: #f5f2f0;
  padding: 47px 40px 40px 40px;
  width: 100%;
  height: 100%;
}

.xButton {
  position: absolute;
  top: 4px;
  right: 4px;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
}

.title {
  margin: 0;
  color: #000000;
  font-weight: 400;
  font-size: 28px;
  line-height: 32px;
  font-family: DM Serif Display;
  text-align: center;
}

.info {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  color: #000000;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  font-family: Oxygen;
  letter-spacing: 0.1em;
  text-align: center;
  text-transform: uppercase;
}
